<template>
  <div class="main publicVCenter">
    <img src="@/assets/image/phone/img1.png" class="bg" />
    <div class="stepWrap publicVCenter">
      <div class="stepItem publicHCenter">
        <div class="imgDesc publicHvCenter">
          <div class="mInputBox publicHvCenter">
            <i class="el-icon-refresh-right"></i>
            <div class="mInput publicHvCenter">hmyd.vip</div>
          </div>
          <div class="gap">or</div>
          <div class="mInputBox publicHvCenter">
            <div class="mInput publicHvCenter">嗨喵悦动</div>
            <div class="sBtn publicHvCenter">搜索</div>
          </div>
        </div>
        <div class="textDesc">
          <p>电脑打开网址</p>
          <p>hmyd.vip</p>
          <p>或百度搜索"嗨喵悦动"</p>
          <p>进入官网</p>
        </div>
      </div>
      <div class="stepItem">
        <p>点击右上角"登录"</p>
        <p>微信扫码即可登入</p>
      </div>
    </div>
    <div class="popupMod publicHvCenter" v-if="popupVisible">
      <div class="posterBox publicVCenter">
        <img src="@/assets/image/phone/img3.png" class="poster" />
        <div
          class="btn publicHvCenter"
          id="copyGZH"
          @click="copy"
          data-clipboard-text="嗨喵悦动"
        >
          复制公众号去关注
        </div>
        <i class="el-icon-close" @click="closePopup"></i>
      </div>
    </div>
  </div>
</template>

<script>
import Clipboard from 'clipboard';

export default {
  name: 'phoneMain',
  metaInfo: {
    title: '嗨喵悦动 - 嗨喵悦动登录_嗨喵悦动大屏互动',
    meta: [
      {
        name: 'keywords',
        content:
          '嗨喵悦动,嗨喵悦动大屏互动,嗨喵悦动官网,嗨喵悦动登录,微信墙,微信互动,现场互动,嗨现场',
      },
      {
        name: 'description',
        content:
          '嗨喵悦动是一款免费便捷炫酷的活动大屏幕现场互动软件，适用于企业年会、酒吧、婚礼、商业庆典峰会等活动现场，包括微信上墙送祝福、扫码签到、抽奖投票、摇一摇红包雨等有趣的大屏互动游戏，让活动更精彩！',
      },
    ],
  },
  data() {
    return {
      popupVisible: true,
    };
  },
  computed: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    closePopup() {
      this.popupVisible = false;
    },
    copy() {
      const clipboard = new Clipboard('#copyGZH');
      clipboard.on('success', (e) => {
        console.log(e);
        this.$message({
          message: '复制成功!',
          type: 'success',
        });
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on('error', (e) => {
        console.log(e);
        this.$message.error('复制失败!');
        // 释放内存
        clipboard.destroy();
      });
    },
  },
};
</script>
<style lang="less" scoped>
.main {
  position: relative;
  flex-direction: column;
  padding-top: 90vw;
  .bg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .stepWrap {
    flex-direction: column;
    width: 100%;
    position: relative;
    .stepItem {
      width: 100%;
      padding-top: 9vw;
      .imgDesc {
        width: 50%;
        flex-direction: column;
        .mInputBox {
          background-color: #cccccc;
          width: 40vw;
          height: 9vw;
          border-radius: 1.1vw;
          i {
            color: #e6e6e6;
            font-size: 4.8vw;
            margin-right: 3vw;
          }
          .mInput {
            width: 65%;
            height: 6vw;
            border-radius: 6.933vw;
            background-color: #f4f4f4;
            color: #ccc;
            font-size: 3.6vw;
          }
          .sBtn {
            width: 25%;
            background-color: #43bbf3;
            color: #f4f4f4;
            border-radius: 2vw;
            font-size: 3vw;
            padding: 1vw;
            margin-left: 2vw;
          }
        }
        .gap {
          font-size: 6.4vw;
          color: #ff8824;
          margin: 0 2vw;
        }
      }
      .textDesc {
        width: 50%;
        p {
          color: #362c24;
          font-size: 3.7vw;
          font-weight: 400;
          margin: 2vw 0;
          padding-left: 4vw;
        }
      }
      &:nth-child(2) {
        padding-top: 12vw;
        p {
          color: #362c24;
          font-size: 3.7vw;
          font-weight: 400;
          margin: 2vw 0;
          padding-left: 7vw;
        }
      }
    }
  }
  .popupMod {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    .posterBox {
      position: absolute;
      flex-direction: column;
      width: 90vw;
      .poster {
        width: 100%;
      }
      .btn {
        width: 60vw;
        height: 16vw;
        background-image: url("../../assets/image/phone/img5.png");
        background-size: 100% 100%;
        position: absolute;
        bottom: 4vw;
        font-size: 4.267vw;
        color: #4d2719;
        font-weight: bold;
      }
      i {
        color: #ffffff;
        position: absolute;
        right: 2vw;
        top: 5vw;
        font-size: 8vw;
        font-weight: bold;
      }
    }
  }
}
</style>
